@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600&display=swap');

.inputsection {
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadeIn 1s ease-in-out 1s forwards;
    opacity: 0;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(-1px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.inputsection:focus-within .searchinput, .searchinput:hover {
    background-color: rgb(37, 40, 42);
    border: 1px solid rgb(37, 40, 42);
    box-shadow:  0 1px 6px 0 rgb(11, 11, 11);
}

.searchinput {
    display: flex;
    box-sizing: border-box;
    align-items: center;
    border: 1.2px solid rgb(108, 108, 108);
    padding: 4px 15px;
    border-radius: 25px;
}

.input {
    width: 480px;
    height: 38px;
    background: transparent;
    color: white;
    border: none;
    padding: 0px 9px;
    font-size: 1.2rem;
    font-family: 'Poppins', 'Comfortaa';
}

input:focus {
    outline: none;
}

.buttons {
    display: flex;
    justify-content: center;
    margin: 0px;
    text-decoration: none;
}

.goBtn {
    border: none;
    background: transparent;
    font-size: 1.2rem;
    letter-spacing: 1px;
    font-weight: 300;
    color: rgb(185, 185, 185);
    padding: 0px;
    font-family: 'Poppins', 'Comfortaa';
}.goBtn:hover {
    cursor: pointer;
    color: rgb(226, 226, 226);
}

.searchButton {
    display: flex;
    justify-content: center;
}

.searchBtn {
    border: 1px solid rgb(93, 94, 95);
    border-radius: 8px;
    background: rgb(20, 21, 21);
    margin-top: 20px;
    font-size: 1.1rem;
    letter-spacing: 1px;
    font-weight: 300;
    color: rgb(157, 157, 157);
    padding: 5px 15px;
    font-family: 'Poppins', 'Comfortaa';
    display: none;
}.searchBtn:hover {
    cursor: pointer;
    color: rgb(226, 226, 226);
}

.errorBox {
    position: absolute;
    text-align: center;
    width: 300px;
    top: 55px;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    color: rgb(255, 210, 210);
    font-family: 'Poppins', 'Comfortaa';
    letter-spacing: 1px;
    z-index: 1;
}

@media screen and (max-width: 580px) {
    .input {
        width: 390px;
        font-size: 1rem;
        height: 32px;
    }
}

@media screen and (max-width: 480px) {
    .input {
        width: 290px;
        font-size: 1rem;
        height: 38px;
    }

    .goBtn {
        display: none;
    }

    .searchBtn {
        display: inline-block;
    }

    .errorBox {
        top: 115px;
    }
}
