.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgb(14, 17, 19);
    z-index: 2;
}

.modal {
    border: none;
    border-radius: 6px;
    background-color: transparent;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    padding: 0;
    padding-bottom: 100px;
    overflow: hidden;
    z-index: 2;
  }
  