@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap');

.recipeCard {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
    color: white;
    min-width: 350px;
    max-width: 700px;
    padding: 25px 50px;
    height: fit-content;
    border: 1px solid rgb(109, 109, 109);
    border-radius: 30px;
    font-size: 1.3rem;
    background-color: rgba(255, 255, 255, 0.079);
}

.recipeText {
    text-align: start;
}

.recipeCard p {
    margin: 0;
    padding: 0;
}

.chefMessage {
    font-size: 1.8rem;
    font-family: 'Poppins';
    letter-spacing: 0.75px;
}

.ingredients {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
}

.gif {
    width: 90%;
}

.buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 25px;
    margin-top: 27px;
    margin-bottom: 5px;
    width: 100%;
    font-family: 'Poppins';
}

.pdfBtn {
    background-color: transparent;
    border: 1px solid rgb(109, 109, 109);
    background-color: rgba(255, 255, 255, 0.057);
    color: rgb(193, 193, 193);
    font-size: 1.2rem;
    border-radius: 50px;
    padding: 10px 22px;
}

.pdfBtn:hover {
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgba(255, 255, 255, 0.125);
}

.firstcloseBtn {
    color: rgb(193, 193, 193);
    font-size: 1.2rem;
    padding: 5px 0px;
    font-family: 'Poppins';
    text-decoration: none;
}

.firstcloseBtn:hover {
    cursor: pointer;
    color: rgb(255, 255, 255);
}

.closeBtn {
    display: inline-block;
    text-align: center;
    position: relative;
    color: rgb(193, 193, 193);
    font-size: 1.2rem;
    padding: 2px 0px;
    font-family: 'Poppins';
    text-decoration: none;
}

.closeBtn::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: 0;
    right: 0;
    background-color: rgb(188, 188, 188);
    transform: scaleX(0);
    transform-origin: bottom center;
    transition: transform 0.20s ease-out;
}

.closeBtn:hover::after {
    transform: scaleX(1);
    transform-origin: bottom center;
}


@media screen and (max-width: 400px) {
    .recipeCard {
        padding: 25px 20px;
    }
    .chefMessage {
        text-align: start;
        font-size: 1.3rem;
    }
     .gif {
        width: 280px;
    }
}
