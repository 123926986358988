.mainLayout {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-top: 29vh;
    overflow-y: auto;
}

@media screen and (max-width: 400px) {
    .mainLayout {
    padding-top: 200px;
  }
}