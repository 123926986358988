@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;700&family=Gruppo&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;700&family=Gruppo&family=Lexend+Peta:wght@100;200;300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300&display=swap');

.header {
  position: relative;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  height: 135px;
  opacity: 0;
  animation: fadeIn 2s ease-in-out forwards;
  /* z-index: 1; */
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* .icon {
  border-radius: 50px;
  font-size: 67px;
  margin-left: -8px;
} */

.closeInfo {
  position: fixed;
  top: 0;
  left: 0;
  width: 110%;
  height: 100%;
}

.menuIcon {
  display: flex;
  position: absolute;
  right: 25px;
  top: 15px;
  font-size: 53px;
  z-index: 2;
  padding: 11px;
  animation: fadeIn 1.5s ease-in-out 1s forwards;
  opacity: 0;
}.menuIcon:hover {
  cursor: pointer;
  background-color: rgba(220, 233, 245, 0.086);
  border-radius: 50%;
}.menuIconActive {
  background-color: rgba(220, 233, 245, 0.086);
  border-radius: 50%;
}

.menuInfo {
  display: flex;
  position: absolute;
  flex-direction: column;
  text-align: center;
  width: 410px;
  right: 25px;
  top: 80px;
  color: white;
  font-family: 'Poppins', 'Comfortaa';
  background-color: rgb(30, 31, 31);
  border-radius: 5px;
  padding: 15px;
  animation: fadeIn .5s ease-in-out forwards;
  opacity: 0;
  z-index: 1;
} @keyframes fadeIn {
  0% {
      opacity: 0;
      transform: translateY(0px);
  }
  100% {
      opacity: 1;
      transform: translateY(0);
  }
}

  .menuInfo h3 {
  margin: 0;
  padding: 2px 0px;
  font-family: 'Poppins', 'Comfortaa';
  font-weight: 300;
  letter-spacing: 1px;
  font-size: 1.5rem;
} .menuInfo h4 {
  margin: 0;
  padding: 2px 0px;
  font-family: 'Poppins', 'Comfortaa';
  font-weight: 300;
  letter-spacing: 1px;
  font-size: 1.1rem;
} .menuInfo p {
  text-align: start;
  margin: 8px 0px;
  padding: 0px 10px;
  padding-top: 10px;
  font-family: 'Poppins', 'Comfortaa';
  text-indent: 1.2rem;
  font-weight: 300;
  letter-spacing: 0.5px;
  border-radius: 5px;
  font-size: 1.1rem;
}

.icon {
  background: linear-gradient(to bottom, rgb(14, 17, 19), rgb(14, 17, 19), rgb(14, 17, 19), rgb(255, 189, 216));
  border-radius: 50px;
  font-size: 67px;
  animation: colorRotation 5s ease-in-out infinite;
  /* margin-left: -8px; */
}

@keyframes colorRotation {
  0% {
    background: linear-gradient(to bottom, rgb(14, 17, 19), rgb(14, 17, 19), rgb(14, 17, 19), rgb(255, 189, 216));
  }
  10% {
    background: linear-gradient(to bottom, rgb(14, 17, 19), rgb(14, 17, 19), rgb(14, 17, 19), rgb(255, 189, 233));
  }
  20% {
    background: linear-gradient(to bottom, rgb(14, 17, 19), rgb(14, 17, 19), rgb(14, 17, 19), rgb(245, 189, 255));
  }
  30% {
    background: linear-gradient(to bottom, rgb(14, 17, 19), rgb(14, 17, 19), rgb(14, 17, 19), rgb(227, 189, 255));
  }
  40% {
    background: linear-gradient(to bottom, rgb(14, 17, 19), rgb(14, 17, 19), rgb(14, 17, 19), rgb(220, 189, 255));
  }
  50% {
    background: linear-gradient(to bottom, rgb(14, 17, 19), rgb(14, 17, 19), rgb(14, 17, 19), rgb(200, 189, 255));
  }
  60% {
    background: linear-gradient(to bottom, rgb(14, 17, 19), rgb(14, 17, 19), rgb(14, 17, 19), rgb(220, 189, 255));
  }
  70% {
    background: linear-gradient(to bottom, rgb(14, 17, 19), rgb(14, 17, 19), rgb(14, 17, 19), rgb(227, 189, 255));
  }
  80% {
    background: linear-gradient(to bottom, rgb(14, 17, 19), rgb(14, 17, 19), rgb(14, 17, 19), rgb(245, 189, 255));
  }
  90% {
    background: linear-gradient(to bottom, rgb(14, 17, 19), rgb(14, 17, 19), rgb(14, 17, 19), rgb(255, 189, 233));
  }
  100% {
    background: linear-gradient(to bottom, rgb(14, 17, 19), rgb(14, 17, 19), rgb(14, 17, 19), rgb(255, 189, 216));
  }
}

.title {
  font-size: 4.2rem;
  letter-spacing: -2.5px;
  /* font-family: 'Comfortaa', cursive; */
  /* font-family: 'Gruppo', cursive; */
  /* font-family: 'Raleway', sans-serif; */
  font-family: 'Lexend Peta', sans-serif;
  font-weight: 300;
  margin: 0;
}

@media screen and (max-width: 400px) {
  .menuIcon {
    top: 10px;
    right: 10px;
  }
  .menuInfo {
    right: 25px;
    width: 320px;
  }

  .header {
    height: 105px;
  }

  .title {
    font-size: 3rem;
    /* margin-left: -8px; */
  }

  .icon {
    font-size: 53px;
  }
}
