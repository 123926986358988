* {
  box-sizing: border-box;
}

html {
  height: 100vh;
  background-color: rgb(14, 17, 19);
  /* background: linear-gradient(to bottom, rgb(0, 0, 0), rgb(0, 0, 0), rgb(153, 153, 153)); */
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
  margin: 0;
  padding: 0;
  overflow-y: auto;
  overflow-x: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 20px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background: rgb(52, 52, 52);
}

::-webkit-scrollbar-track {
  background: rgba(62, 62, 62, 0.188);
}
